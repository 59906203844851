export const USER_COOKIE = "appointment-auth";

// auth data
export function getAuthData() {
  const AuthData = localStorage.getItem(USER_COOKIE);
  //  console.log(AuthData)
  if (typeof AuthData === "string") return JSON.parse(AuthData);
  else return {};
}

export function setAuthData(auth) {
  const strState = JSON.stringify(auth);
  localStorage.setItem(USER_COOKIE, strState);
}








export function getCustomCookies() {
  const CustomCoockies = localStorage.getItem('CUSTOM_COOCKIES');
  //  console.log(AuthData)
  if (typeof CustomCoockies === "string") return JSON.parse(CustomCoockies);
  else return { customCoockies: "" };
}
