import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, DatePicker, Input, Select } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { DynamicList, PageTitle } from "../components/Shared";
import {
  CHECK_AVAILABILITY,
  CREATE_NEW_APPOINTMENT,
  FETCH_ALL_MENU,
} from "../graphql/modules";
import { formValidator } from "../util";
import { successNotify, warnNotify } from "../util/notification";

const CreateAppointment = () => {
  const history = useHistory();

  const [timeConflict, setTimeConflict] = useState("");
  // state initialization
  const [inputData, setInputData] = useState({
    date: "",
    phone: "",
    numOfPeople: "",
    menu: "",
  });
  const [error, setError] = useState({
    phone: "",
  });

  // fetch all menu
  const { data } = useQuery(FETCH_ALL_MENU);
  const allMenuList = data?.FetchMenuList?.menu || [];
  // create appointment
  const [CreateAppointmentMutation, { loading }] = useMutation(
    CREATE_NEW_APPOINTMENT
  );
  const handleSubmit = async () => {
    try {
      const {
        data: { CreateApointment },
      } = await CreateAppointmentMutation({
        variables: {
          date: inputData?.date,
          dateString: moment(Number(inputData.date)).local().format(
            "MM-DD-YYYY hh:mm A"
          ),
          numOfPeople: Number(inputData?.numOfPeople),
          phone: inputData?.phone,
          menu: inputData?.menu,
        },
      });
      if (CreateApointment.success) {
        successNotify(CreateApointment.message);
        history.push("/dashboard");
      } else {
        warnNotify(CreateApointment.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    formValidator({ name, value, userData: inputData, error, setError });
    setInputData({ ...inputData, [name]: value });
  };

  const [CheckAvailablityMutation] = useMutation(CHECK_AVAILABILITY);
  const onCheckDate = async (date) => {
    try {
      const {
        data: { HasOtherAppointment },
      } = await CheckAvailablityMutation({
        variables: {
          date: moment(Number(date)).local().format("MM-DD-YYYY hh:mm A"),
        },
      });
      if (HasOtherAppointment.success) {
        setTimeConflict("Another appointment is requested at the same time");
      } else {
        setTimeConflict("");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleDate = (value) => {
    // console.log("modify->", moment(value).format("x"));
    if (value) {
      setInputData({ ...inputData, date: value.local().format("x") });
      onCheckDate(moment(value).local().format("x"));
    } else {
      setInputData({ ...inputData, date: "" });
    }
  };
  const disableTime = (hour, current) => {
    const arr = [];
    if (current?.format("D") <= new Date().getDate()) {
      for (let i = 0; i <= hour; i++) {
        arr.push(i);
      }
    } else if (!current) {
      for (let i = 0; i <= hour; i++) {
        arr.push(i);
      }
    }
    return arr;
  };

  // disable check
  const disable =
    !inputData?.date ||
    !inputData?.menu ||
    !inputData?.numOfPeople ||
    !inputData?.phone ||
    error?.phone;

  return (
    <DynamicList title="Request New Appointment" tableHide>
      <section id="create-appointment">
        <div className="row">
          <div className="col-md-3">
            <div className="common-input">
              <div>
                <label>Select Date & Time</label>
              </div>
              <DatePicker
                style={{ width: "100%" }}
                size="large"
                showTime={{
                  defaultValue: moment("00:00:00", "hh:mm").local(),
                  format: "hh:mm",
                }}
                onChange={handleDate}
                format="MM-DD-YYYY hh:mm A"
                value={inputData?.date ? moment(inputData?.date, "x").local() : ""}
                disabledDate={(current) =>
                  current < moment(Date.now()).local().startOf("day")
                }
                disabledTime={(current) => ({
                  disabledHours: () =>
                    disableTime(new Date().getHours(), current),
                })}
              />
              {timeConflict && (
                <small style={{ color: "orangered" }}>{timeConflict}</small>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="common-input">
              <div>
                <label>Phone</label>
              </div>
              <Input
                size="large"
                name="phone"
                onChange={handleInput}
                value={inputData?.phone}
                placeholder="(201) 23456789"
              />
              {error.phone && (
                <small style={{ color: "orangered" }}>
                  Phone number is not correct!
                </small>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="common-input">
              <div>
                <label>Number of Guests</label>
              </div>
              <Select
                style={{ width: "100%" }}
                size="large"
                onChange={(value) =>
                  setInputData({ ...inputData, numOfPeople: value })
                }
                placeholder="Select number"
                // value={inputData?.numOfPeople}
              >
                {new Array(10).fill(null).map((opt, idx) => (
                  <Select.Option key={idx+1}>{idx+1}</Select.Option>
                ))}
              </Select>
              {/* <Input
                size="large"
                type="number"
                name="numOfPeople"
                onChange={handleInput}
                placeholder="Number of guest"
                value={inputData?.numOfPeople}
              /> */}
            </div>
          </div>
          <div className="col-md-3">
            <div className="common-input">
              <div>
                <label>Select Service(s)</label>
              </div>
              <Select
                style={{ width: "100%" }}
                size="large"
                placeholder="Select services"
                onChange={(value) =>
                  setInputData({ ...inputData, menu: value })
                }
                mode="multiple"
                placement="bottomLeft"
              >
                <Select.Option key="" disabled>
                  Select Services
                </Select.Option>
                {allMenuList?.map((menu) => (
                  <Select.Option key={menu?._id}>
                    <div className="d-flex justify-content-between">
                      <p>{menu?.name}</p>
                      <p>{menu?.price}</p>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="text-end">
            <Button
              type="primary"
              loading={loading}
              onClick={handleSubmit}
              disabled={disable}
            >
              Send Request
            </Button>
          </div>
        </div>
      </section>
    </DynamicList>
  );
};

export default CreateAppointment;
