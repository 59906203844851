import React from "react";

const L2Experience = () => {
  return (
    <section id="l2-experience">
      <div className="container">
        <div className="row">
          <div className="col-12 mb-4">
            <img style={{maxHeight:"350px", objectFit:"cover"}} src="/img/landing2/af-advice.png" alt="" />
          </div>
          <div className="col-md-6 col-12">
            <h2 className="l2-section-title text-start">
              Experienced on many projects with a large company all around the
              world
            </h2>
          </div>
          <div className="col-md-6 col-12">
            <p className="mt-0 mt-lg-2 mb-4">
              Founded by a former NFL cheerleader, Naihta is a high-quality,
              affordable manicure and pedicure service. We meet our customers at
              their homes or business when it's convenient for them and provide
              them with luerious treatments in the sunctuary of their own space
              for as litle as thirty dollars.
            </p>
            {/* <a className="l2-btn" role="button">Read More</a> */}
          </div>
        </div>
      </div>
    </section>
  );
};
export default L2Experience;
