import { Drawer } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as Scroll from "react-scroll";

const AuthHeader = () => {
  const [visible, setVisible] = useState(false);

  const handleScroll = () => {
    Scroll.scroller.scrollTo("footer", {
      duration: 300,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <div id="custom-header">
      <div className="container header-inner">
        <div className="app-logo">
          <Link to="/">
            <img src="/img/logo-2.png" alt="logo" />
          </Link>
        </div>
        <div className="app-menu d-none d-lg-block">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/signin">Appointment</Link>
            </li>
            <li>
              <a href="void:javascript()" onClick={handleScroll}>
                Contact
              </a>
            </li>
          </ul>
        </div>
        <div className="app-menu d-none d-lg-block">
          <ul>
            <li>
              <Link to="/signin">Login</Link>
            </li>
          </ul>
        </div>
        <div className="d-block d-lg-none">
          <i className="fas fa-bars" onClick={() => setVisible(true)}></i>
        </div>
        <Drawer
          placement="right"
          onClose={() => setVisible(false)}
          open={visible}
          style={{ zIndex: "9999999" }}
          width={250}
          closeIcon={false}
          headerStyle={{ display: "flex", justifyContent: "end" }}
          className="mobile-app-menu"
          closable={false}
        >
          <div className="text-end">
            <i className="fas fa-times" onClick={()=>setVisible(false)}></i>
          </div>
          <ul>
            <li onClick={() => setVisible(false)}>
              <i className="fas fa-home me-2"></i>
              <Link to="/">Home</Link>
            </li>
            <li onClick={() => setVisible(false)}>
              <i className="fas fa-calendar-check me-2"></i>
              <Link to="/signup">Appointment</Link>
            </li>
            <li onClick={() => setVisible(false)}>
              <i className="fas fa-sign-in-alt me-2"></i>
              <Link to="/signin">Login</Link>
            </li>
          </ul>
        </Drawer>
      </div>
    </div>
  );
};

export default AuthHeader;
