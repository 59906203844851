import React from "react";

const L2Header = () => {
  window.onscroll = function () {
    scrollFunction();
  };

  const scrollFunction = () => {
    if (
      document.body.scrollTop > 101 ||
      document.documentElement.scrollTop > 101
    ) {
      document.getElementById("l2-header").classList.add("navScroll");
    }
    if (document.documentElement.scrollTop < 80) {
      document.getElementById("l2-header").classList.remove("navScroll");
    }
  };
  return (
    <div className="l2-header" id="l2-header">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <nav
              className="navbar navbar-expand-lg navbar-light"
              id="l2-navbar"
            >
              <a className="navbar-brand" href="#l2-hero">
                TGH Cuts
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className="collapse navbar-collapse justify-content-end"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mr-auto">
                  {/* <li className="nav-item active me-3">
                    <a href="#l2-hero" className="nav-link">
                      Home
                    </a>
                  </li> */}
                  <li className="nav-item me-3">
                    <a className="nav-link" href="#l2-hero">About us</a>
                  </li>
                  <li className="nav-item me-3">
                    <a href="#l2-services" className="nav-link">
                      Services
                    </a>
                  </li>
                  <li className="nav-item me-3">
                    <a
                      className="nav-link"
                      href="/documents/price2.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      role="button"
                    >
                      Prices
                    </a>
                  </li>
                  <li className="nav-item me-3">
                    <a href="#l2-testimonials" className="nav-link">
                      Reviews
                    </a>
                  </li>
                  <li className="nav-item me-3">
                    <a href="#l2-contact" className="nav-link">
                      Contacts
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default L2Header;
