import { notification } from "antd";

const endpoint = "https://assetperfectnailsspasalon.sfo3.digitaloceanspaces.com/";
// const noImage = "/images/Rectangle 6.png";
const noImage = "/images";
export const getImage = (image) => {
  if (!image) return noImage;
  return endpoint + image;
};

const noImageuser = "/img/no-user.jpg";
export const getImageUser = (image) => {
  if (!image) return noImageuser;
  return endpoint + image;
};

export const showNotification = (data) => {
  if (data.success) {
    notification.success({
      message: data.message,
      placement: "bottomLeft",
    });
  } else {
    notification.error({
      message: data.message,
      placement: "bottomLeft",
    });
  }
};


export const FullName = (data) => {
  let name = "";
  if (data?.firstname) {
    name = name.concat(data?.firstname);
  }
  if (data?.firstname) {
    name = name.concat(" ", data?.lastname);
  }
  return name;
};