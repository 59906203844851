import React from "react";
import { Link } from "react-router-dom";
import * as Scroll from "react-scroll";
const AuthFooter = () => {
  return (
    <Scroll.Element key={"footer"}>
      <div>
        <div className="footer">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-6">
                <div className="footer-first">
                  <div className="footer-title">
                    <h6>Links</h6>
                  </div>
                  <ul>
                    <li>
                      <Link to="signin">Appointment</Link>
                    </li>
                    <li>
                      <Link to="signup">Register</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 col-4 text-center d-none d-md-block">
                <div className="footer-title">
                  <h6>Contact Us</h6>
                </div>
                <ul>
                  <p style={{ fontWeight: "500" }}>Perfect Nail Salon & Spa</p>
                  <p>11012 Canyon Rd E #44, Puyallup, WA 98373</p>
                  <p>Phone: (253) 536-8976</p>
                </ul>
              </div>
              <div className="col-md-4 col-6 text-end">
                <div className="footer-title">
                  <h6>Follow Us</h6>
                </div>
                <div className="d-flex justify-content-end footer-icon">
                  <a style={{ color: "#4267B2" }}>
                    <i className="fab fa-facebook-square me-2"></i>
                  </a>
                  <a
                    style={{ color: "#833AB4" }}
                    target="_blank"
                    href="https://www.instagram.com/perfectnailsspasalon/ "
                  >
                    <i className="fab fa-instagram me-2"></i>
                  </a>
                  <a
                    style={{ color: "rgb(145 144 76)" }}
                    target="_blank"
                    href="https://www.snapchat.com/add/perfectnailswa?share_id=bez815Xftdo&locale=en-US"
                  >
                    <i className="fab fa-snapchat-square"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="row d-block d-md-none mt-5">
              <div className="col-12 text-center">
                <div className="footer-title">
                  <h6>Contact Us</h6>
                </div>
                <ul>
                  <p style={{ fontWeight: "500" }}>Perfect Nail Salon & Spa</p>
                  <p>11012 Canyon Rd E #44, Puyallup, WA 98373</p>
                  <p>Phone: (253) 536-8976</p>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-end">
          <p>Copyright © {new Date().getFullYear()}</p>
        </div>
        <div style={{ height: "5px" }}></div>
        <div style={{ height: "5px", background: "#786b6bb5" }}></div>
      </div>
    </Scroll.Element>
  );
};

export default AuthFooter;
