import gql from "graphql-tag";

export const FETCH_ALL_APPOINTMENT = gql`
  query FetchApointmentList(
    $limit: Int
    $offset: Int
    $userId: ID
    $filterData: ApointmentFilter
    $fromWaitList: Boolean
  ) {
    FetchApointmentList(
      limit: $limit
      offset: $offset
      userId: $userId
      filterData: $filterData
      fromWaitList: $fromWaitList
    ) {
      code
      success
      message
      count
      apointment {
        date
        numOfPeople
        phone
        menu {
          name
          price
          _id
        }
        _id
        user {
          _id
          phone
          firstname
          lastname
        }
        status
        createdAt
      }
    }
  }
`;

export const CREATE_NEW_APPOINTMENT = gql`
  mutation CreateApointment(
    $date: String
    $numOfPeople: Int
    $menu: [ID]
    $phone: String
    $dateString: String
  ) {
    CreateApointment(
      date: $date
      numOfPeople: $numOfPeople
      menu: $menu
      phone: $phone
      dateString: $dateString
    ) {
      code
      success
      message
    }
  }
`;

export const EDIT_APPOINTMENT = gql`
  mutation EditApointment($inputData: ApointmentEditInput, $id: ID) {
    EditApointment(inputData: $inputData, id: $id) {
      code
      success
      message
    }
  }
`;

export const CHECK_AVAILABILITY = gql`
  mutation HasOtherAppointment($date: String) {
    HasOtherAppointment(date: $date) {
      code
      success
      message
    }
  }
`;

export const LOGIN_ANDCREATE_APPOINTMENT = gql`
  mutation LoginAndCreateAppointment(
    $email: String!
    $password: String!
    $date: String
    $dateString: String
    $phone: String
    $numOfPeople: Int
    $menu: [ID]
  ) {
    LoginAndCreateAppointment(
      email: $email
      password: $password
      date: $date
      dateString: $dateString
      phone: $phone
      numOfPeople: $numOfPeople
      menu: $menu
    ) {
      code
      success
      message
    }
  }
`;
