import React from "react";
import {
  L2Advice,
  L2Experience,
  // L2Faq,
  L2Footer,
  L2Header,
  L2Hero,
  // L2Provide,
  L2Services,
  L2Testimonials,
  L2Getintouch
} from "../components";

const LandingPage2 = () => {
  return (
    <div id="landing_page2">
      <L2Header />
      <L2Hero/>
      <L2Services />
      <L2Advice />
      <L2Experience />
      {/* <L2Provide /> */}
      {/* <L2Faq /> */}
      <L2Testimonials/>
      <L2Getintouch/>
      <L2Footer/>
    </div>
  );
};

export default LandingPage2;
