import { useMutation } from "@apollo/react-hooks";
import { Button, Card, Input } from "antd";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { RESET_PASSWORD } from "../graphql/modules/auth";
import { formValidator, successNotify, warnNotify } from "../util";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

const ResetPassword = (props) => {
  // state initialize
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [cpHide, setCpHide] = useState(true);
  const [pHide, setPHide] = useState(true);
  const [error, setError] = useState({
    password: "",
  });

  const { id } = useParams();

  const handleInput = (e) => {
    const { name, value } = e.target;
    setPassword(e.target.value);
    formValidator({ name, value, userData: { password }, error, setError });
  };

  const handleConfirmPass = (e) => {
    setCpassword(e.target.value);
  };

  // new password set api
  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD);
  const handleSubmit = async () => {
    try {
      const {
        data: { ResetPassowrd },
      } = await resetPassword({
        variables: {
          securityCode: id,
          newPassword: password,
        },
      });
      if (ResetPassowrd.success) {
        successNotify(ResetPassowrd.message);
        props.history.push("/signin");
      } else {
        warnNotify(ResetPassowrd.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // disable submit button
  const disable = !password || !cpassword || password !== cpassword || error.password;

  return (
    <div id="auth-section">
      <div className="container">
        <div className="row justify-content-around">
          <div className="col-md-4 col-sm-12">
            <Card style={{ borderRadius: "10px" }}>
              <div className="section-title">
                <h2>Reset Password</h2>
              </div>
              <div className="row">
                <div className="auth-input col-md-12">
                  <div>
                    <label>New Password <sup style={{ color: "red" }}>*</sup></label>
                  </div>
                  <Input
                    size="large"
                    type={pHide ? "password" : ""}
                    placeholder="New Password"
                    value={password}
                    onChange={handleInput}
                    name="password"
                    suffix={
                      pHide ? (
                        <EyeInvisibleOutlined onClick={() => setPHide(false)} />
                      ) : (
                        <EyeOutlined onClick={() => setPHide(true)} />
                      )
                    }
                  />
                  {error?.password && (
                    <small>
                      Password cannot be empty and must be a minimum of 8
                      characters!
                    </small>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="auth-input col-md-12">
                  <div>
                    <label>Confirm Password <sup style={{ color: "red" }}>*</sup></label>
                  </div>
                  <Input
                    size="large"
                    type={cpHide ? "password" : ""}
                    value={cpassword}
                    placeholder="Confirm Password"
                    onChange={handleConfirmPass}
                    name="cpassword"
                    suffix={
                      cpHide ? (
                        <EyeInvisibleOutlined
                          onClick={() => setCpHide(false)}
                        />
                      ) : (
                        <EyeOutlined onClick={() => setCpHide(true)} />
                      )
                    }
                  />
                  {cpassword && cpassword !== password && (
                    <small>Password doesn't match!</small>
                  )}
                </div>
              </div>
              <div className="submit">
                <div className="w-100">
                  <Button
                    disabled={disable || loading}
                    loading={loading}
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={handleSubmit}
                    size="large"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
