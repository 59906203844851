import React from "react";

const L2Hero = () => {
  return (
    <section id="l2-hero">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-12">
            <div className="l2-hero-left">
              <div className="l2-hero-content">
                <h2 className="mb-2 mb-md-4">
                  A Style Maven with a Passion for Hair Transformation
                </h2>
                <p className="mb-2 mb-md-5">
                  Step into the world of hair artistry, where creativity and
                  expertise intertwine to craft stunning looks that captivate
                  and empower. At TGH Cuts, a prominent women-led hair salon
                  nestled in the heart of Milton, Washington.
                </p>
                <p className="mb-2 mb-md-5">
                  Stop by TGH Cuts, and let us weave our magic, transforming
                  your hair and reigniting your confidence. Discover the
                  artistry, dedication, and professionalism that have made TGH
                  Cuts an indispensable part of the Milton community's haircare
                  landscape.
                </p>
                {/* <a role="button" className="l2-btn">
                  Get Started
                </a> */}
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-none d-lg-block">
            <div className="row gx-5 l2-right-content align-items-center">
              <div className="col-6">
                <img
                  className="l2-hero-big-img"
                  src="/img/landing2/hero-first.png"
                  alt=""
                />
              </div>
              <div className="col-5">
                <img
                  className="l2-hero-mini-img"
                  src="/img/landing2/hero-second.png"
                  alt=""
                />
              </div>
              {/* <div className="pastels-nails">
                <h6 className="l2-mini-title">Pastel Nails</h6>
                <p className="mb-3">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Repellat, minima qui beatae nihil necessitatibus molestias.
                </p>
                <p>Try Now</p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default L2Hero;
