import React from "react";
// import {
//   InstagramOutlined,
//   FacebookOutlined,
//   TwitterOutlined,
// } from "@ant-design/icons";
import {Link} from 'react-router-dom';

const L2Footer = () => {
  return (
    <div className="landing2_footer">
    <div className="upper_section">
      <div className="container">
        <div className="row">
          <div className="col-4">
            <div className="footer_title">
              <h5>Location</h5>
            </div>
            <div className="footer_info">
              <p>2800 Milton Way UNIT 6</p>
              <p>Milton, WA 98354</p>
            </div>
          </div>
          <div className="col-4">
            <div className="footer_title">
              <h5>Contact us</h5>
            </div>
            <div className="footer_info">
              <p>(253) 927-8444</p>
            </div>
          </div>
          {/* <div className="col-4 footer_logo">
            <Link to="/">
              <img src="/img/logo_lts.png" />
            </Link>
          </div> */}
        </div>
      </div>
    </div>
    <div className="bottom_section mt-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="d-md-flex justify-content-between align-items-center mb-5">
              <div className="copyright text-center text-md-start mt-3">
                Copyright <span style={{fontSize:"16px"}}>&copy;</span> {new Date().getFullYear()} TGH Cuts Salon
              </div>
              <div className="policy d-flex d-md-block justify-content-between mt-3">
                <p className="d-inline-block me-4">Privacy & Policy</p>
                <p className="d-inline-block">Terms of use</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default L2Footer;
