import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { formValidator, successNotify, warnNotify } from "../../util";
import { Button, Card, DatePicker, Input, Select } from "antd";
import moment from "moment";
import {
  CHECK_AVAILABILITY,
  FETCH_ALL_MENU,
  LOGIN_ANDCREATE_APPOINTMENT,
} from "../../graphql/modules";
const AddWaitlist = ({ refetch }) => {

  const [readOnly, setReadOnly] = useState(true);
  const [timeConflict, setTimeConflict] = useState("");
  // state initialization
  const [userData, setUserData] = useState({
    email: "",
    password: "",
    date: "",
    numOfPeople: "",
    menu: [],
    phone: "",
  });

  const [error, setError] = useState({
    phone: "",
    email: "",
  });

  // fetch all menu
  const { data } = useQuery(FETCH_ALL_MENU);
  const allMenuList = data?.FetchMenuList?.menu || [];
  // create appointment
  const [CreateAppointmentMutation, { loading }] = useMutation(
    LOGIN_ANDCREATE_APPOINTMENT
  );
  const handleSubmit = async () => {
    try {
      const {
        data: { LoginAndCreateAppointment },
      } = await CreateAppointmentMutation({
        variables: {
          email: userData?.email,
          password: userData?.password,
          date: userData?.date,
          dateString: moment(Number(userData.date)).local().format(
            "MM-DD-YYYY hh:mm A"
          ),
          numOfPeople: Number(userData?.numOfPeople),
          phone: userData?.phone,
          menu: userData?.menu,
        },
      });
      if (LoginAndCreateAppointment.success) {
        successNotify(LoginAndCreateAppointment.message);
        refetch();
        setUserData({});
      } else {
        warnNotify(LoginAndCreateAppointment.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
    formValidator({ name, value, userData, error, setError });
  };

  const [CheckAvailablityMutation] = useMutation(CHECK_AVAILABILITY);
  const onCheckDate = async (date) => {
    try {
      const {
        data: { HasOtherAppointment },
      } = await CheckAvailablityMutation({
        variables: {
          date: moment(Number(date)).local().format("MM-DD-YYYY hh:mm A"),
        },
      });
      if (HasOtherAppointment.success) {
        setTimeConflict("Another appointment is requested at the same time");
      } else {
        setTimeConflict("");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleDate = (value) => {
    if (value) {
      setUserData({ ...userData, date: value.format("x") });
      onCheckDate(value.format("x"));
    } else {
      setUserData({ ...userData, date: "" });
    }
  };

  // disable check
  const disable =
    !userData?.date ||
    userData?.menu?.length === 0 ||
    !userData?.numOfPeople ||
    !userData?.email ||
    !userData?.password ||
    !userData?.phone ||
    error?.phone;

  const disableTime = (hour, current) => {
    const arr = [];
    if (current?.format("D") <= new Date().getDate()) {
      for (let i = 0; i <= hour; i++) {
        arr.push(i);
      }
    } else if (!current) {
      for (let i = 0; i <= hour; i++) {
        arr.push(i);
      }
    }
    return arr;
  };
  return (
    <div className="col-md-12 col-sm-12 mt-5">
      <Card
        style={{
          borderRadius: "10px",
        }}
      >
        {/* <div className="section-title">
          <h2>
            Register <div className="section-underline" />
          </h2>
        </div> */}
        <div className="row">
          <div className="auth-input col-md-3">
            <div>
              <label>
                Email <sup>*</sup>
              </label>
            </div>
            <Input
              size="large"
              placeholder="Ex. example@domain.com"
              readOnly={readOnly}
              onFocus={ () => setReadOnly(false) }
              onBlur={ () => setReadOnly(true) }
              value={userData?.email}
              onChange={handleInput}
              name="email"
              type="email"
            />
            {error?.email && (
              <small style={{ color: "orangered" }}>
                Email format is not correct or empty!
              </small>
            )}
          </div>
          <div className="auth-input col-md-3">
            <div>
              <label>
                Password <sup>*</sup>
              </label>
            </div>
            <Input
              size="large"
              type={"password"}
              placeholder="Password"
              value={userData?.password}
              onChange={handleInput}
              name="password"
            />
          </div>
          <div className="auth-input col-md-3">
            <div>
              <label>Select Date & Time</label>
            </div>
            <DatePicker
              style={{ width: "100%" }}
              size="large"
              showTime={{
                defaultValue: moment("00:00:00", "hh:mm").local(),
                format: "hh:mm",
              }}
              onChange={handleDate}
              format="MM-DD-YYYY hh:mm A"
              value={userData?.date ? moment(userData?.date, "x").local() : ""}
              disabledDate={(current) =>
                current < moment(Date.now()).local().startOf("day")
              }
              disabledTime={(current) => ({
                disabledHours: () =>
                  disableTime(new Date().getHours(), current),
              })}
            />
            {timeConflict && (
              <small style={{ color: "orangered" }}>{timeConflict}</small>
            )}
          </div>
          <div className="auth-input col-md-3">
            <div>
              <label>Number of Guests</label>
            </div>
            <Select
              style={{ width: "100%" }}
              size="large"
              onChange={(value) =>
                setUserData({ ...userData, numOfPeople: value })
              }
              placeholder="Select number"
              // value={inputData?.numOfPeople}
            >
              {new Array(10).fill(null).map((opt, idx) => (
                <Select.Option key={idx + 1}>{idx + 1}</Select.Option>
              ))}
            </Select>
          </div>
          <div className="auth-input col-md-3">
            <div>
              <label>Phone</label>
            </div>
            <Input
              size="large"
              type="number"
              name="phone"
              onChange={handleInput}
              placeholder="(201) 23456789"
              value={userData?.phone}
            />
            {error.phone && (
              <small style={{ color: "orangered" }}>
                Phone number is not correct!
              </small>
            )}
          </div>
          <div className="auth-input col-md-3">
            <div>
              <label>Select Service(s)</label>
            </div>
            <Select
              style={{ width: "100%" }}
              size="large"
              placeholder="Select services"
              onChange={(value) => setUserData({ ...userData, menu: value })}
              mode="multiple"
              placement="bottomLeft"
              value={userData?.menu}
            >
              <Select.Option key="" disabled>
                Select Services
              </Select.Option>
              {allMenuList?.map((menu) => (
                <Select.Option key={menu?._id}>
                  <div className="d-flex justify-content-between">
                    <p>{menu?.name}</p>
                    <p>{menu?.price}</p>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="submit">
          <div className="text-center">
            <Button
              disabled={disable || loading}
              style={{ width: "200px" }}
              loading={loading}
              type="primary"
              size="large"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AddWaitlist;
