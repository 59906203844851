import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";

const NotFound = () => {
  const pageTitle = `The page you’re looking for can’t be found.`;
  return (
    <div className="col-md-12 text-center mt-5" style={{ minHeight: "50vh" }}>
      <img
        src="/img/not-found.png"
        alt="nodata"
        style={{ maxHeight: "50vh", marginBottom: "50px" }}
      />
      <h5>{pageTitle}</h5>
      <div className="mb-5 mt-5">
        <Link to="/">
          <Button
            size="large"
            type="primary"
            style={{ background: "#2c2c2c", borderColor: "#2c2c2c", borderRadius:"7px" }}
            className="d-block m-auto"
          >
            Go to Home
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
