import React, { useEffect } from "react";
import { Accordion, Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import { logoutUser } from "../../store/modules/auth";
import { sidebar } from "./sidebar";

export const SideNavbar = ({setExpand, onClickCloseMenu, expand}) => {
    const dispatch = useDispatch();
  const { pathname } = useLocation();
  const onLogout = (e) => {
    e.preventDefault();
    dispatch(logoutUser());
  };

  useEffect(()=>{
    onClickCloseMenu();
  },[window.location.href])
  return (
    <div className="sidebar-menu">
      <div className="left-menu-wrapper">
        <div className="close-menu mb-2" onClick={onClickCloseMenu}>
          <a href="void:javascript()">
            <i className="fas fa-times"></i>
          </a>
        </div>
        <div className="left-logo">
          <h2>
            <Link style={{ color: "#fff", visibility:"hidden" }} to="/dashboard">
              Appointment
            </Link>
          </h2>
        </div>
        <ul className="left-menu">
          {sidebar.map((item) => {
            if (item.submenu?.length > 0) {
              return (
                <li key={item.path}>
                  <Accordion>
                    <Card>
                      <Accordion.Toggle
                        eventKey="0"
                        onClick={() => setExpand(!expand)}
                      >
                        <i className={item.icon}></i> {item.label}
                        <i
                          style={{
                            fontSize: "10px",
                            marginLeft: "60px",
                            fontWeight: "inherit",
                          }}
                          className={`text-right fas fa-arrow-${
                            expand ? "up" : "down"
                          }`}
                        ></i>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0" className="sub-category">
                        <Card.Body>
                          {item.submenu.map((subItem) => (
                            <li key={subItem.path}>
                              <NavLink exact={subItem.exact} to={subItem.path}>
                                <i className={subItem.icon}></i> {subItem.label}
                              </NavLink>
                            </li>
                          ))}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </li>
              );
            } else {
              const isActiveMenu = pathname
                .toLowerCase()
                .includes(item.path.toLowerCase())
                ? "active"
                : "";
              return (
                <li key={item.path}>
                  <NavLink
                    exact={item.exact}
                    to={item.path}
                    activeClassName={isActiveMenu}
                  >
                    <i className={item.icon}></i> {item.label}
                  </NavLink>
                </li>
              );
            }
          })}
          <li>
            <a href="#/" onClick={onLogout}>
              <i className="fas fa-sign-out-alt"></i> Logout
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
