export const sidebar = [
  {
    path: "/create-appointment",
    label: "New Appointment",
    icon: "far fa-calendar-check",
  },
  {
    path: "/dashboard",
    label: "My Appointments",
    icon: "fas fa-history",
  },
  {
    path: "/appointment-que",
    label: "Waitlist",
    icon: "fas fa-spinner",
  },
  {
    path: "/my-profile",
    label: "Profile",
    icon: "far fa-user",
  },
];
