import React from "react";
import { useSelector } from "react-redux";
import { getImageUser } from "../../util";
import { SideNavbar } from "./SideNav";

const PrivateLayout = (props) => {
  const { user } = useSelector((state) => state.auth);

  const onClickToggleMenu = () => {
    // setExpand(true);
    document.querySelector(".sidebar-menu").classList.add("open");
    document.body.classList.add("open");
  };

  const onClickCloseMenu = () => {
    document.querySelector(".sidebar-menu").classList.remove("open");
    document.body.classList.remove("open");
  };

  return (
    <section className="main-section">
      <SideNavbar onClickCloseMenu={onClickCloseMenu} />
      <div
        style={{
          background: "#3c4441",
          height: "62px",
          position: "sticky",
          top: "0",
          zIndex: "999",
          width: "calc(100% - 280px)",
          float: "right",
        }}
        className="px-5 d-lg-flex justify-content-end align-items-center d-none"
      >
        <div className="text-right">
          <img
            src={getImageUser(user?.avater)}
            width="50px"
            style={{
              borderRadius: "50%",
              aspectRatio: "1/1",
              objectFit: "cover",
            }}
          />
        </div>
      </div>
      <div className="right-main-content">
        <div className="mobile-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="mobile-header-wrapper">
                  <div className="mobile-logo">
                    {/* <Link to="/dashboard" style={{ width: "80px" }}>
                      <h2>Appointment</h2>
                    </Link> */}
                  </div>
                  <div className="toggle-menu" onClick={onClickToggleMenu}>
                    <a href="void:javascript()" className="toggle-icon">
                      <i className="fas fa-bars"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-none d-lg-block mb-5"></div>
          {props.children}
      </div>
    </section>
  );
};

export default PrivateLayout;
