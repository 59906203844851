import React from "react";

const L2Advice = () => {
  return (
    <section id="l2-service_advice">
      <div className="container">
        <div className="row gx-5 gy-5">
          <div className="col-md-6 col-12">
            <img src="/img/landing2/save_time.jpg" alt="Save time img" />
          </div>
          <div className="col-md-6 col-12">
            <h2 className="l2-section-title">
              Epitome of professional and <br />
              tailored hair services
            </h2>
            <p className="mb-5">
              Whether you're seeking a trendy bob, a vibrant balayage, a
              nourishing keratin treatment, or an elegant updo for a special
              occasion, our dedicated team will provide personalized
              consultations and deliver outstanding results that exceed your
              expectations.
            </p>
            {/* <a role="button" href="tel:(253) 927-8444" className="l2-btn">
              Get Started
            </a> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default L2Advice;
