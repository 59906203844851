import {
  // ForgotPassword,
  // Home,
  // NewEmailVerify,
  // ResetPassword,
  // SignIn,
  // SignUpPage,
  // Dashboard,
  // CreateAppointment,
  // MyProfile,
  // AppointmentQue,
  // EmailVerifyDialogue,
  // PasswordChangeCon,
  // ResetPassInstruction,
  // Unsubscribe,
  // Terms,
  // LandingPage,
  LandingPage2,
  // LandingPage3,
} from "../pages";

// Login or Sign in Routes
export const Auth = [
  // { path: "/signup", exact: true, component: SignUpPage },
  // { path: "/signin", exact: true, component: SignIn },
  // { path: "/forgot-password", exact: true, component: ForgotPassword },
  // { path: "/reset-password/:id", component: ResetPassword },

  // { path: "/", exact: true, component: LandingPage },
];

// Public Routes
export const Public = [
  { path: "/", exact: true, component: LandingPage2 },
  // { path: "/general2", exact: true, component: LandingPage2 },
  // { path: "/general3", exact: true, component: LandingPage3 },
  // { path: "/prices", exact: true, component: Prices },
  // { path: "/change-email", exact: true, component: NewEmailVerify },
  // { path: "/terms", exact: true, component: Terms },
  // {
  //   path: "/email-verify-confirmation/:id",
  //   exact: true,
  //   component: EmailVerifyDialogue,
  // },
  // {
  //   path: "/password-change-confirmation",
  //   exact: true,
  //   component: PasswordChangeCon,
  // },
  // {
  //   path: "/reset-request-success",
  //   exact: true,
  //   component: ResetPassInstruction,
  // },
  // { path: "/unsubscribe", exact: true, component: Unsubscribe },
];
// Private Routes
export const Private = [
  // { path: "/dashboard", exact: true, component: Dashboard },
  // { path: "/create-appointment", exact: true, component: CreateAppointment },
  // { path: "/appointment-que", exact: true, component: AppointmentQue },
  // { path: "/my-profile", exact: true, component: MyProfile },
];

export const Common = [];
