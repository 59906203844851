import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { USER_LOGIN } from "../../graphql/modules/auth";
import { successNotify, warnNotify } from "../../util";
import { Button, Card, Input } from "antd";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "../../store/modules/auth";
const Login = ({ title = false }) => {
  const dispatch = useDispatch();
  // registration input state initialize
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });

  // initilize dispatch hook

  // submit create account
  const [onLogin, { loading }] = useMutation(USER_LOGIN);
  const handleSubmit = async () => {
    try {
      const {
        data: { Login },
      } = await onLogin({
        variables: {
          ...userData,
        },
      });
      if (Login.success) {
        dispatch(loginUser(Login));
        successNotify(Login.message);
      } else {
        warnNotify(Login.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const disable = !userData?.email || !userData?.password;

  return (
    <Card
      style={{
        borderRadius: "10px",
        maxWidth:"636px"
      }}
      bordered={false}
    >
      <div className="section-title">
        {!title && <h2>Welcome back again</h2>}
      </div>
      <div className="row">
        <div className="auth-input col-md-12">
          <div>
            <label>Email or Phone <sup>*</sup></label>
          </div>
          <Input
            size="large"
            type="email"
            placeholder="Ex. example@domain.com"
            value={userData?.email}
            onChange={handleInput}
            name="email"
          />
        </div>
        <div className="auth-input col-md-12">
          <div>
            <label>
              Password <sup>*</sup>{" "}
            </label>
          </div>
          <Input
            size="large"
            type="password"
            value={userData?.password}
            onChange={handleInput}
            name="password"
          />
        </div>
      </div>
      <div className="params d-block d-lg-flex justify-content-between mb-3">
        <div className="d-flex">
          <p className="me-3">Don't have an account?</p>
          <Link to="/signup">Register</Link>
        </div>
        <Link to="/forgot-password">Forget Password?</Link>
      </div>
      <div className="submit">
        <div className="w-100">
          <Button
            style={{ width: "100%" }}
            type="primary"
            size="large"
            onClick={handleSubmit}
            disabled={disable}
            loading={loading}
          >
            Login
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default Login;
