import React from "react";

import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper-bundle.min.css";

// import required modules
import { Navigation } from "swiper";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Rate } from "antd";

const L2Testimonial = () => {
  return (
    <div className="l2-testimonials" id="l2-testimonials">
      <div className="container">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <h2 className="l2-section-title">What Our Client Say</h2>
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              navigation={{
                prevEl: "#prev_btn",
                nextEl: "#next_btn",
              }}
              breakpoints={{
                768:{
                  slidesPerView: 2
                }
              }}
              modules={[Navigation]}
              className="mySwiper"
            >
              {testimonials.map(({ text, name, imageSrc, value }, idx) => (
                <SwiperSlide className="single_testimonial" key={idx}>
                  <div className="review_text_box">
                    <p>{text}</p>
                    <div className="quota" />
                  </div>
                  <div className="user_identity justify-content-start justify-content-md-end justify-content-lg-center">
                    <img src={imageSrc} alt="image" />
                    <div>
                      <p className="name">{name}</p>
                      <Rate
                        className="user-rating"
                        disabled
                        defaultValue={value}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="next_prev_icons">
              <div className="next_prev_icons_inner">
                <LeftOutlined id="prev_btn" />
                <RightOutlined id="next_btn" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default L2Testimonial;

const testimonials = [
  {
    text: "I got in a couple minutes before my appointment. She is was incredibly kind. She did a amazing job on my hair. The colors look absolutely gorgeous.",
    imageSrc: "/img/landing2/rachel.png",
    name: "Rachel gaston",
    value: 5,
  },
  {
    name: "Jt T",
    text: "Absolutely love this place. I go in every 4 weeks and she always remembers who you are, knows your name, makes you feel comfortable and is very personable.",
    imageSrc: "/img/landing2/jt.png",
    value: 4,
  },
  {
    name: "Chris Watson",
    text:"I've been going to Jenny for over 15 years. She cut my dad's hair and now does my two boys' hair as well. I'm very happy she took over the business. I wouldn't go anywhere else.",
    imageSrc: "/img/landing2/chris.png",
    value: 5,
  },
  {
    name: "Laura Pocasangre",
    text: "First time on this place and I love it! If you’re looking for a nice salon with profesional hair dresser, this is the place! Jenny was Amazing, she cut and styled my hair, perfect hair cut.",
    imageSrc: "/img/landing2/laura.png",
    value: 4,
  },
];
