import React from "react";
import { Layout } from "antd";
import AuthHeader from "./AuthHeader";
import AuthFooter from "./AuthFooter";

const AuthLayout = ({ children }) => {
  return (
    <Layout>
      <AuthHeader />
      {children}
      <AuthFooter />
    </Layout>
  );
};

export default AuthLayout;
