import { useMutation } from "@apollo/react-hooks";
import { Button, DatePicker, Input, Modal, Popconfirm, Select } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { CHECK_AVAILABILITY, EDIT_APPOINTMENT } from "../../graphql/modules";
import { successNotify, formValidator } from "../../util";

export const EditAppointment = ({ data, refetch, allMenuList = [] }) => {
  const [inputData, setInputData] = useState({
    date: moment(data?.date, "x").local(),
    phone: data?.phone,
    numOfPeople: data?.numOfPeople,
    menu: data?.menu?.map((mn) => mn?._id),
    status: data?.status,
  });
  const [error, setError] = useState({
    phone: "",
  });
  const [visible, setVisible] = useState(false);

  const [timeConflict, setTimeConflict] = useState("");

  // update api
  const [UpdateApointmentMutation, { loading }] = useMutation(EDIT_APPOINTMENT);
  const onUpdate = async () => {
    try {
      const {
        data: { EditApointment },
      } = await UpdateApointmentMutation({
        variables: {
          inputData: {
            ...inputData,
            dateString: moment(Number(inputData.date)).local().format(
              "MM-DD-YYYY hh:mm A"
            ),
            date: moment(inputData?.date).local().format("x"),
            numOfPeople: Number(inputData?.numOfPeople),
          },
          id: data?._id,
        },
      });
      if (EditApointment?.success) {
        successNotify(EditApointment?.message);
        setVisible(false);
        refetch();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // cancel appointment
  const handleChangeStatus = async (status) => {
    try {
      const {
        data: { EditApointment },
      } = await UpdateApointmentMutation({
        variables: {
          inputData: {
            status,
          },
          id: data?._id,
        },
      });
      if (EditApointment?.success) {
        successNotify(EditApointment?.message);
        refetch();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // check availability
  const [CheckAvailablityMutation] = useMutation(CHECK_AVAILABILITY);
  const onCheckDate = async (date) => {
    try {
      const {
        data: { HasOtherAppointment },
      } = await CheckAvailablityMutation({
        variables: {
          date: moment(Number(date)).local().format("MM-DD-YYYY hh:mm A"),
        },
      });
      if (HasOtherAppointment.success) {
        setTimeConflict("Another appointment is requested at the same time");
      } else {
        setTimeConflict("");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
    formValidator({ name, value, userData: inputData, error, setError });
  };

  const handleDate = (value) => {
    if (value) {
      setInputData({ ...inputData, date: moment(value, "x").local() });
      onCheckDate(moment(value).local().format("x"));
    } else {
      setInputData({ ...inputData, date: "" });
    }
  };

  const disableTime = (hour, current) => {
    const arr = [];
    if (current?.format("D") <= new Date().getDate()) {
      for (let i = 0; i <= hour; i++) {
        arr.push(i);
      }
    } else if (!current) {
      for (let i = 0; i <= hour; i++) {
        arr.push(i);
      }
    }
    return arr;
  };

  const disable =
    !inputData?.date ||
    inputData?.menu?.length === 0 ||
    !inputData?.numOfPeople ||
    !inputData?.phone ||
    error?.phone;
  return (
    <div>
      {/* <EditOutlined onClick={() => setVisible(true)} /> */}
      <Button type="primary" className="me-2" onClick={() => setVisible(true)}>
        Edit
      </Button>
      <Popconfirm
        title="Are you sure?"
        onConfirm={() => handleChangeStatus("Cancelled")}
        okType="danger"
      >
        <Button type="danger" style={{ borderRadius: "10px" }}>
          Cancel
        </Button>
      </Popconfirm>

      <Modal
        open={visible}
        title="Update Appointment"
        okText="Update"
        onCancel={() => setVisible(false)}
        okButtonProps={{ disabled: disable, loading: loading }}
        onOk={onUpdate}
      >
        <div className="common-input">
          <div>
            <label>Select Date & Time</label>
          </div>
          <DatePicker
            style={{ width: "100%" }}
            size="large"
            showTime={{
              defaultValue: moment("00:00", "hh:mm A").local(),
              format: "hh:mm A",
            }}
            onChange={handleDate}
            format="MM-DD-YYYY hh:mm A"
            value={inputData?.date ? moment(inputData?.date, "x").local() : ""}
            disabledDate={(current) => current < moment().local().endOf("day")}
            disabledTime={(current) => ({
              disabledHours: () => disableTime(new Date().getHours(), current),
            })}
          />
          {timeConflict && (
            <small style={{ color: "orangered" }}>{timeConflict}</small>
          )}
        </div>
        <div className="common-input">
          <div>
            <label>Phone</label>
          </div>
          <Input
            size="large"
            name="phone"
            onChange={handleInput}
            value={inputData?.phone}
          />
          {error.phone && (
            <small style={{ color: "orangered" }}>
              Phone number is not correct!
            </small>
          )}
        </div>
        <div className="common-input">
          <div>
            <label>Number of guests</label>
          </div>
          <Select
            style={{ width: "100%" }}
            size="large"
            onChange={(value) =>
              setInputData({ ...inputData, numOfPeople: value })
            }
            value={inputData?.numOfPeople}
          >
            {new Array(10).fill(null).map((opt, idx) => (
               <Select.Option key={idx+1}>{idx+1}</Select.Option>
            ))}
          </Select>

          {/* <Input
            size="large"
            type="number"
            name="numOfPeople"
            onChange={handleInput}
            value={inputData?.numOfPeople}
          /> */}
        </div>
        <div className="common-input">
          <div>
            <label>Select Service(s)</label>
          </div>
          <Select
            style={{ width: "100%" }}
            size="large"
            placeholder="Select services"
            onChange={(value) => setInputData({ ...inputData, menu: value })}
            mode="multiple"
            value={inputData?.menu || []}
          >
            <Select.Option key="" disabled>
              Select Services
            </Select.Option>
            {allMenuList?.map((menu) => (
              <Select.Option key={menu?._id}>
                <div className="d-flex justify-content-between">
                  <p>{menu?.name}</p>
                  <p>{menu?.price}</p>
                </div>
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="common-input">
          <div>
            <label>Status</label>
          </div>
          <Select
            size="large"
            style={{ width: "100%" }}
            onChange={(value) => setInputData({ ...inputData, status: value })}
            value={inputData?.status}
          >
            <Select.Option key="Cancelled">Cancel</Select.Option>
            <Select.Option key="Upcoming">Upcoming</Select.Option>
          </Select>
        </div>
      </Modal>
    </div>
  );
};
