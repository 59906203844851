import gql from "graphql-tag";

export const FETCH_ALL_MENU = gql`
  query FetchMenuList($limit: Int, $offset: Int) {
    FetchMenuList(limit: $limit, offset: $offset) {
      code
      success
      message
      count
      menu {
        name
        price
        _id
        createdAt
      }
    }
  }
`;
