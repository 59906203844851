import React from "react";

const Button = ({ btnText, btnClass='', loading, style={}, disabled, onClick=()=>console.log('use event'), ...props }) => {
  const onPress = (e) => {
    e.preventDefault();
    onClick();
  };

  return (
    <button
      type="submit"
      className={`btn btn-login btn-common ${btnClass}`}
      disabled={disabled || loading}
      onClick={onPress}
      style={style}
    >
      {loading && <i className="fas fa-spinner fa-spin" style={{marginRight:'10px'}}></i>}
      {btnText}
    </button>
  );
};
Button.propsType = {
  btnText: String,
  loading: Boolean,
  disabled: Boolean,
  onClick: Function,
  btnClass: String,
  style:Object
};

export default Button;
