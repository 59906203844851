import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper-bundle.min.css";
import { Navigation } from "swiper";

const L2Services = () => {
  return (
    <section id="l2-services">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
          <h2 className="l2-section-title">See Our Featured Services</h2>
            <div className="services_inner">
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                navigation={{
                  prevEl: "#prev_btn",
                  nextEl: "#next_btn",
                }}
                breakpoints={{
                  576:{
                    slidesPerView: 2
                  },
                  768:{
                    slidesPerView: 3
                  }
                }}
                modules={[Navigation]}
                className="mySwiper"
              >
                {services.map(({ text, imageSrc }, idx) => (
                  <SwiperSlide key={idx}>
                    <div className="single_service">
                      <p>{text}</p>
                      <img src={imageSrc} alt="image" />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="d-flex align-items-center justify-content-between service_bottom">
                <a
                  href="/documents/price2.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  role="button"
                  className="page_btn"
                >
                  See All Services
                </a>
                <div className="next_prev_icons">
                  <LeftOutlined id="prev_btn" />
                  <RightOutlined id="next_btn" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default L2Services;

const services = [
  { text: "Men’s Haircut", imageSrc: "/img/landing2/mens-hair.png" },
  { text: "Women’s Haircut", imageSrc: "/img/landing2/womens-hair.png" },
  { text: "Kid’s Haircut", imageSrc: "/img/landing2/kids-hair.png" },
  { text: "Perms", imageSrc: "/img/landing2/perms-hair.png" },
  { text: "Color & Highlights", imageSrc: "/img/landing2/color-hair.png" },
];
